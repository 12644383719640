@import "variables";
@import "colors";
@import "mixins";

input {
  border-radius: 0 0 5px 5px;
  font-size: $input_font_size_large;
  line-height: $input_height_large;
  height: $input_height_large;
  background-color: $cremewhite;
  border: 0;

  @media (max-height: $input_breakpoint_height) {
    height: $input_height_small;
    line-height: $input_height_small;
  }

  @include setResponsiveFont;

  &:focus {
    background-color: $inputfocus;
  }

  &.MuiInput-input:focus {
    background-color: inherit;
  }

  &.MuiInputBase-input {
    padding-top: 7px;
    padding-bottom: 4px;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px !important;
    }
  }
}

textarea {
  clear: both;
  position: relative;
  margin-top: 2rem;
  font-size: $input_font_size_large;
  background-color: transparent;
  height: 10rem;
  border: 0;
  box-shadow: none;
  resize: none;
}

*:focus {
  outline: none !important;
}

[disabled] {
  background-color: #ccc;
}

.MuiSelect-select.MuiSelect-select {
  padding-top: 7px;
  padding-bottom: 4px;
}