/*
*
* Tinybots mixins
*
*/

@mixin set-responsive-background() {

  @media (min-width: 0px) and (max-width: 550px) {
    background-image:url('../images/responsive/tessa-blurred-480.jpg');
  }

  @media (min-width: 551px) and (max-width: 700px) {
    background-image:url('../images/responsive/tessa-blurred-640.jpg');
  }

  @media (min-width: 701px) and (max-width: 800px) {
    background-image:url('../images/responsive/tessa-blurred-800.jpg');
  }

  @media (min-width: 801px) and (max-width: 1200px) {
    background-image:url('../images/responsive/tessa-blurred-1024.jpg');
  }

  @media (min-width: 1201px) and (max-width: 1320px) {
    background-image:url('../images/responsive/tessa-blurred-1280.jpg');
  }

  @media (min-width: 1321px) and (max-width: 2200px) {
    background-image:url('../images/responsive/tessa-blurred-1600.jpg');
  }

  @media (min-width: 2200px) and (max-width: 3900px) {
    background-image:url('../images/responsive/tessa-blurred-2600.jpg');
  }

  @media (min-width: 3901px) {
    background-image:url('../images/responsive/tessa-blurred-4k.jpg');
  }
}

@mixin verticalcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontalcenter {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center {
  position: relative;
  left: 50%; top:50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin flexcenter {
  display:flex;
  align-items: center;
  justify-content: center;
}

@mixin setMargin($side,$amount) {
  margin-#{$side}: #{$amount};
}

@mixin colorBullet($category, $othercolor) {
  background: linear-gradient(to left, $othercolor 85%, $category 15%);
}

@mixin setResponsiveFont {
  @media (max-width: $smaller_width_breakpoint_1)  {
    font-size: $label_font_size_large;
  }

  @media (max-height: $smallest_height_breakpoint) {
    font-size: $label_font_size_large;
  }
}

@mixin horizontalflex($position : flex-start){
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: $position;
  justify-content: $position;
  -webkit-align-items: center;
  align-items:center;
}

@mixin verticalflex($position : center){
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-justify-content: $position;
  justify-content: $position;
  -webkit-align-items: stretch;
  align-items:center;
}


@mixin badge() {
  width:25px;
  height:25px;
  border-radius:25px;
  line-height:25px;
}
