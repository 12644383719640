@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Raleway-regular/Raleway-regular.eot');
  src: url('../../assets/fonts/Raleway-regular/Raleway-regular.eot?#iefix') format('embedded-opentype'),
  local('Raleway'),
  local('Raleway-regular'),
  url('../../assets/fonts/Raleway-regular/Raleway-regular.woff2') format('woff2'),
  url('../../assets/fonts/Raleway-regular/Raleway-regular.woff') format('woff'),
  url('../../assets/fonts/Raleway-regular/Raleway-regular.ttf') format('truetype'),
  url('../../assets/fonts/Raleway-regular/Raleway-regular.svg#Raleway') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Montserrat-regular/Montserrat-regular.eot');
  src: url('../../assets/fonts/Montserrat-regular/Montserrat-regular.eot?#iefix') format('embedded-opentype'),
  local('Montserrat Regular'),
  local('Montserrat-regular'),
  url('../../assets/fonts/Montserrat-regular/Montserrat-regular.woff2') format('woff2'),
  url('../../assets/fonts/Montserrat-regular/Montserrat-regular.woff') format('woff'),
  url('../../assets/fonts/Montserrat-regular/Montserrat-regular.ttf') format('truetype'),
  url('../../assets/fonts/Montserrat-regular/Montserrat-regular.svg#Montserrat') format('svg');
}

@font-face {
  font-family:'Montserrat-Bold';
  src:url('../../assets/fonts/Montserrat-bold/Montserrat-Bold.eot?') format('eot'),
  url('../../assets/fonts/Montserrat-bold/Montserrat-Bold.woff') format('woff'),
  url('../../assets/fonts/Montserrat-bold/Montserrat-Bold.ttf') format('truetype'),
  url('../../assets/fonts/Montserrat-bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Montserrat-light/montserratlight.woff2') format('woff2'),
  url('../../assets/fonts/Montserrat-light/montserratlight.woff') format('woff'),
  url('../../assets/fonts/Montserrat-regular/Montserrat-regular.ttf') format('truetype')
}

@font-face {
  font-family : 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-regular/Roboto-regular.eot');
  src: url('../../assets/fonts/Roboto-regular/Roboto-regular.eot?#iefix') format('embedded-opentype'),
  local('Roboto'),
  local('Roboto-regular'),
  url('../../assets/fonts/Roboto-regular/Roboto-regular.woff2') format('woff2'),
  url('../../assets/fonts/Roboto-regular/Roboto-regular.woff') format('woff'),
  url('../../assets/fonts/Roboto-regular/Roboto-regular.ttf') format('truetype'),
  url('../../assets/fonts/Roboto-regular/Roboto-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Mono';
  src: url('../../assets/fonts/Roboto-mono/RobotoMono-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Mono';
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-mono/RobotoMono-Light.ttf');
}