$icon-font-path: '/assets/fonts/icon-fonts/';
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~react-datepicker/dist/react-datepicker.css";

@import "colors";
@import "variables";
@import "mixins";
@import "outline";
@import "fonts";
@import "forms";
@import "main";
