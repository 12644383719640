$darkgrey : rgba(51,51,51,1);
$darkgrey80 : rgba(51,51,51,.8);
$darkgrey60 : rgba(51,51,51,.6);
$darkgrey40 : rgba(51,51,51,.4);
$darkgrey20 : rgba(51,51,51,.2);

$darkestgrey : #090909;

$grey: rgba(78,78,78,1);

$lightgrey : rgba(111,111,111,1);
$lightgrey80 : rgba(111,111,111,.8);
$lightgrey60 : rgba(111,111,111,.6);
$lightgrey40 : rgba(111,111,111,.4);
$lightgrey30 : rgba(111,111,111,.3);
$lightgrey20 : rgba(111,111,111,.2);
$lightgrey10 : rgba(111,111,111,.1);

$lightestgrey: rgba(216, 216, 216, 1);
$lightestgrey80: rgba(216, 216, 216, 0.8);
$lightestgrey60: rgba(216, 216, 216, 0.6);
$lightestgrey40: rgba(216, 216, 216, 0.4);
$lightestgrey20: rgba(216, 216, 216, 0.2);

$cremewhite : rgba(232,228,218,1);
$cremewhite80 : rgba(232,228,218,.8);
$cremewhite60 : rgba(232,228,218,.6);
$cremewhite40 : rgba(232,228,218,.4);
$cremewhite20 : rgba(232,228,218,.2);

$applegreen : #5fc90d;
$lightgreen : rgba(72,147,22,1);
$lightgreen80 : rgba(72,147,22,.8);
$lightgreen60 : rgba(72,147,22,.6);
$lightgreen40 : rgba(72,147,22,.4);
$lightgreen20 : rgba(72,147,22,.2);

$darkgreen : rgba(63,122,25,1);
$darkgreen80 : rgba(63,122,25,.8);
$darkgreen60 : rgba(63,122,25,.6);
$darkgreen40 : rgba(63,122,25,.4);
$darkgreen20 : rgba(63,122,25,.2);

$orange : rgba(247,107,51,1);
$orange80 : rgba(247,107,51,.8);
$orange60 : rgba(247,107,51,.6);
$orange40 : rgba(247,107,51,.4);
$orange20 : rgba(247,107,51,.2);
$orange10 : rgba(247,107,51,.10);

$beige : rgba(181,160,131,1);
$beige80 : rgba(181,160,131,.8);
$beige60 : rgba(181,160,131,.6);
$beige40 : rgba(181,160,131,.4);
$beige20 : rgba(181,160,131,.2);
$beige10 : rgba(181,160,131,.1);

$inputfocus : rgb(252,251,247);

$warning : #da4725;
$warning60 : rgba(218, 71, 37, 0.6);

/* categories */

$other : rgba(111,111,111,1);
$other80 : rgba(111,111,111,.8);
$other60 : rgba(111,111,111,.6);
$other40 : rgba(111,111,111,.4);
$other20 : rgba(111,111,111,.2);

$health : rgba(164,107,178,1);
$health80 : rgba(164,107,178,.8);
$health60 : rgba(164,107,178,.6);
$health40 : rgba(164,107,178,.4);
$health20 : rgba(164,107,178,.2);

$selfcare : rgba(70,178,157,1);
$selfcare80 : rgba(70,178,157,.8);
$selfcare60 : rgba(70,178,157,.6);
$selfcare40 : rgba(70,178,157,.4);
$selfcare20 : rgba(70,178,157,.2);

$sustenance : rgba(50,77,92,1);
$sustenance80 : rgba(50,77,92,.8);
$sustenance60 : rgba(50,77,92,.6);
$sustenance40 : rgba(50,77,92,.4);
$sustenance20 : rgba(50,77,92,.2);

$household : rgba(140,14,22,1);
$household80 : rgba(140,14,22,.8);
$household60 : rgba(140,14,22,.6);
$household40 : rgba(140,14,22,.4);
$household20 : rgba(140,14,22,.2);

$act_visits : rgba(240,202,77,1);
$act_visits80 : rgba(240,202,77,.8);
$act_visits60 : rgba(240,202,77,.6);
$act_visits40 : rgba(240,202,77,.4);
$act_visits20 : rgba(240,202,77,.2);

$music : rgba(232, 120, 24, 1);
$music80 : rgba(232, 120, 24, .8);
$music60 : rgba(232, 120, 24, .6);
$music40 : rgba(232, 120, 24, .4);
$music20 :rgba(232, 120, 24, .2);

$script : rgba(110,204,237, 1);
$script80 : rgba(110,204,237, .8);
$script60 : rgba(110,204,237, .6);
$script40 : rgba(110,204,237, .4);
$script20 : rgba(110,204,237, .2);

$test : rgba(240,202,77,1);
