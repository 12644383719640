@import "colors";
@import "mixins";
@import "variables";

*, *::after, *::before {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  background-size:cover;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat:no-repeat;
  font-size: 12px;
  font-family: 'Raleway', 'Helvetica';
  box-sizing: border-box;
  @include set-responsive-background;
}

html,body {
  height:100%;
  min-height:100%;
}

h1,h1,h3,h4,h5,h6 {
  font-family: 'Montserrat', 'Helvetica';
}

a:hover{
  cursor: pointer;
}

button {
  font-family : 'Roboto', 'Helvetica';
}

#root {
  height: 100%;
}
